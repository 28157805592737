import React from "react";

import Page from "lib/@cms/components/layout/Page";
import { FormApplicationTemplate } from "lib/@forms/components/primitive";
import LOAN_APPLICATION_TEMPLATE from "data/forms-templates/loan-application.json";

export default function LoanApplicationPage() {
  return (
    <Page
      cms={{
        pageData: {
          MetaData: { title: "Loan Application", noRobots: true },
        },
      }}
      pathname="loan-application"
    >
      {() => <Form />}
    </Page>
  );
}

// --- Components ---

export function Form() {
  return (
    <FormApplicationTemplate
      data={LOAN_APPLICATION_TEMPLATE}
      securedFormFriendlyName={process.env.GATSBY_SECURED_FORM_LOAN_APPLICATION_NAME}
    />
  );
}
